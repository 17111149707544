<template lang="pug">
<!-- question(:title="模态框标题" :show="模态框开关" :close="模态框开关关联方法" @next="回答正确回调") -->
div.question-box
  modal(:title="title" :show="show" :closeIco="closeIco" @close="close")
    div.mod.small.its
      div.padding._10_0 {{isMultiple?'多':'单'}}选题
      div.tit {{question}}
      ul.items
       li.flex.start.mod(v-for="n,i in items" @click="_check(i)")
        div.num {{['A', 'B', 'C', 'D'][i]}}
        div.grow {{n}}
        div.ico.right(:class='{active: answers[i]}')
    div.center.commit
      span.txt.red(@click="_commint") 确 认
    div.result.center(v-if="showResult")
      p
        | 本题正确答案：
        span(v-for="a,i in rightKey") {{a / 1 ? ['A','B','C','D'][i] : ''}}
</template>
<script>
import modal from '@/components/modal/index'
export default {
  components: {modal},
  props: {
    title: String, // 模态框标题
    question: String, // 题目
    items: Array, // 选项
    rightKey: Array,// 正确答案
    closeIco: Boolean, // 是否有关闭模态框的按钮
    show: Boolean, // 是否显示
    close: Function, // 关闭开关关联
  },
  computed: {
    isMultiple(){
      return this.rightKey ? (this.rightKey.reduce((p, c)=> p/1 + c/1) > 1) : false
    }
  },
  data(){return{
    answers: [0, 0, 0, 0],
    showResult: false,
  }},
  methods: {
    _check(i){
      if(this.isMultiple){
        this.answers[i] = this.answers[i]? 0 : 1
      }else{
        this.answers = Array(this.items.length).fill(0)
        this.answers[i] = 1
      }
    },
    _commint(){
      let right = this.answers.join('') == this.rightKey.join('')
      this.showResult = true
      setTimeout(()=>{
        this.$emit('commit', right)
      }, 1400)
    }
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/func.less";
.modal-box{
  .ico{
    &:not(.active){
      --blue: #ddd;
    }
    .__rem(5, margin-left);
    .__rem(3, margin-top);
    .__icon(12, 2);
  }
  .num{.__rem(5, margin-right);}
  .its{
    .__border(bottom, #ccc);
  }
  .commit{
    .__spacing(margin, 15);
    span{
      .__border(bottom, var(--red));
    }
  }
}
</style>
