<template lang="pug">
div#ido-pei
  div.banner: img(src="@/assets/img/zhanpic.png")
  div(v-if="!isOver")
    div.state.rel.padding._5.flex.bg(:class="['grey', 'green', 'red'][state]").center
      div.margin(:class="['', 'ico right', 'ico wrong'][state]")
      span.grow {{['答题中', '正确', '错误'][state]}}
    div.quest
      div.words.flex.center
        div.word(v-for="a in answer"): div {{a}}
      div.info.txt.gray.small.center.margin._0_10 请按照顺序点击下面文字，与保密相关词汇
      div.words.flex.center
        div.word(v-for="(a,i) in charts" @click="_pick(i)"): div(:class="{active: charts_i.includes(i)}") {{a}}
    div.next.center.txt.red(@click="_next") 下一关
    question(title="答题过关" :show="modalShow" :close="_modalShow" @commit="_nextGate" :question="qdata.question" :rightKey="qdata.rightKey" :items="qdata.items")/

</template>
<script>
import question from '@/components/modal/question'
export default {
  components: {question},
  data(){return{
    state: 0, // 0答题中 1正确 2错误
    answer: ['', '', '', ''],
    charts: ['为', '密', '力', '体', '载', '大', '涉', '春'],
    rightChart: '',
    charts_i: [],
    modalShow: false,
    qdata: {},
    isOver: false, // 闯关结束
  }},
  methods: {
    _pick(i){
      let len = this.answer.join('').length
      if(len < 4){
        this.answer[len] = this.charts[i]
        this.charts_i.push(i)
      }
      if(len == 3){// 最后一个字
        this.state = this.answer.join('') == this.rightChart ? 1 : 2
        if(this.state == 2){
          this.over()
        }
      }
    },
    _modalShow(){
      this.modalShow = !this.modalShow
    },
    _nextGate(state){
      if(state){
        this.initExam('wcyBattleNext', {guess: 1})
      }else{
        this.over()
      }
      // 请求新题目
      this._modalShow()
    },
    _next(){
      this._modalShow()
    },
    over(){// 结束
      this.isOver = true
      this.$store.commit('tipShow', '闯关失败')
      this.initExam('wcyBattleNext', {guess: 0})
    },
    initExam(uriFunc, data={}){
      this.$req[uriFunc](data, (res)=>{
        let {question_num: flag} = res
        if(flag){
          let {guess: {g_correct, g_library, g_length}, questionArr} = res
          this.charts = g_library
          this.rightChart = g_correct
          this.answer = Array(g_length/1).fill('')
          this.charts_i = []

          this.qdata.question = questionArr[0].eqname
          let rightKey = []
          this.qdata.items = questionArr[0].answer_list.map((a)=>{
            rightKey.push(a.is_right)
            return a.answer_con
          })
          this.qdata.rightKey = rightKey
        }else{
          this.isOver = true
          this.$store.commit('tipShow', {msg: res.question_text, duration: 2500})
        }
      })
    },
  },
  mounted(){
    this.initExam('wcyBattle')
  }
}
</script>
<style lang="less" scoped>
@import "~@/assets/less/func.less";
#ido-pei{
  .state{
    margin: 1.5rem auto;
    .__rem(90, width);
    .__rem(5, border-radius);
    .ico{
      .__rem(5, margin-left);
      --blue: white; --red: white;
      .__icon(12, 2);
    }
  }
  .quest{
    width: 70%;
    margin: 0 auto;
    .words{
      justify-content: space-between;
      flex-wrap: wrap;
      .word{
        width: 25%;
        .__spacing(margin, 10, 0);
        div{
          border-style:solid; border-color: var(--red); .__rem(2, border-width); .__rem(5, border-radius);
          .__wh(50, 50); .__rem(50, line-height); margin:0 auto;
          color: var(--red); .__rem(26); font-weight: bold;
          &.active{background-color:#FFD042;}
        }
      }
    }
    .info{
      line-height: 2;
      .__border(top, var(--gray));
    }
  }
  .next{
    text-decoration: underline;
    .__rem(60, padding-top);
  }
}
</style>